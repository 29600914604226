body {
  background: #f9f9f9;
}

.App-header {
  background-color: #fff;
  border-bottom: 1px solid #eee;
  /* padding: 1em; */
}

.App-header .col-xs-12 {
  display: flex;
  align-items: center;
  padding-top: 1em;
  padding-bottom: 1em;
}

.App-logo {
  max-height: 46px;
}

.App-header h1 {
  color: #808080;
  margin: 0 0 0 2em;
  font-size: 1.75em;
}

.section h2,
.section h3 {
  color: #808080;
}

.section-severity {
  max-width: 240px;
}

.row-summary {
  margin-bottom: 2em;
}

.summary-textarea {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 0.5em;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.align-center {
  align-items: center;
}

.App-saveBtn {
  margin: 3em 0 2em;
  background: #fff;
  border: 1px solid #77c8d2;
  padding: 0.375em 2em 0.375em 1.75em;
  border-radius: 2.25em;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.App-saveBtn:disabled {
  border: 1px solid #ccc;
  background: #f8f8f8;
}

.App-saveBtn span {
  display: inline-block;
  margin-left: 0.75em;
  font-size: 1.5em;
  font-weight: bold;
  color: #77c8d2;
}

.App-saveBtn:disabled span {
  color: #999;
}

.ActionPlanForm {
  position: relative;
}
