.Accordion {
  max-width: 600px;
  margin-bottom: 1em;
}

.Accordion .label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Accordion :hover {
    cursor: pointer;
}

.Accordion .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgba(119, 200, 210, 0.2);
  width: 50px;
  height: 50px;
}

.icon-title {
  display: flex;
  align-items: center;
  gap: 20px;
}

.Accordion .accordion-title {
  font-weight: 600;
  font-size: 0.9em;
  max-width: 200px;
}

.Accordion .chevron-up {
  rotate: -90deg;
}

.Accordion .chevron-down {
  rotate: 90deg;
}

.Accordion .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px;
}