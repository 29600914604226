.AppFallbackLayout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: #444;
  max-width: 500px;
  margin: 0 auto;
}

.AppFallbackLayout p {
  line-height: 24px;
}
