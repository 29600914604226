.SideMenu {
  background-color: #FFFFFF;
  min-width: 400px;
  max-width: 400px;
}

.SideMenu_container {
  padding: 1em 0.5em 1em 0.5em;
}

.SideMenu .dropdown {
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: center;
}

.dropdown_container {
  margin-right: 3em;
  margin-left: 3em;
}

.SideMenu ul {
  list-style: none;
  margin: 0;
  padding-left: 20px;
}

.option_select {
  background-color: transparent;
  border: 2px solid #627293;
  color: #627293;
}

.datepicker {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 1em 0 1.5em;
  margin-top: 20px;
}

.summary-consultation {
  font-weight: 700;
}

.datepicker .label {
  font-weight: 700;
}

.datepicker_input {
  border: 2px solid #77C8D2;
  padding: 1em;
  border-radius: 1em;
}

.SideMenu ul .item {
  border-bottom: 1px solid rgba(196, 196, 196, 0.19);
  padding: 1em 0 1em 0;
}

.SideMenu ul .item:hover {
  cursor: pointer;
  background-color: #dad9d9;
  transition: all ease-in 0.3s;
}

.SideMenu ul .item div div {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
}

.menu_container {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  justify-items: start;
  align-items: center;
}

.SideMenu ul .item div .icon {
  border-radius: 50%;
  background-color: rgba(119, 200, 210, 0.2);
  width: 50px;
  height: 50px;
}

.SideMenu ul .item div .title {
  font-size: 1em;
  font-weight: 600;
}

.SideMenu ul .item div .chevron {
  width: 20px;
  height: 20px;
}

.button_submit {
  display: flex;
  justify-content: center;
  margin: 2em;
}

.add-resume-button {
  background-color: #77c8d2;
  margin: 10px auto;
}

.add-resume-button span{
  color: #fff;
  margin: 0;
}