.Tab1{
    width: 100%;
    min-width: 750px;
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    padding-left: 20px;
}

.Tab1 .table{
  display: flex;
  flex-grow: 1;
}


.Tab1 .table .col-left{
    width: 50%;
    padding: 10px 10px 0 10px;
    box-sizing: border-box;
    border-right: 4px solid #F0F0F0;
}

.Tab1 .table .col{
  width: 50%; 
  padding: 10px;
  box-sizing: border-box;
}

.Tab1 .title{
  color: #77C8D2;
  font-weight: 700;
  font-size: 2em;
}

.Tab1 .container-label{
  display: flex;
  gap: 10px;
  align-items: center;
  border-bottom: 4px solid #F0F0F0;
}

.Tab1 .container-label p{
  font-weight: 500;
  font-size: 1.1em;
}

.Tab1 .container{
  max-width: 100%;
}

.Tab1 .container .accordion-description{
  font-size: 1em;
  font-weight: 500;
}

.Tab1 .accordion-cta-container{
  display: flex;
  align-items: center;
  gap: 10px;
}

.accordion-action-container{
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.accordion-action-container label{
  font-size: 1em;
  font-weight: 500;
}

.accordion-action-container .time-per-day{
  display: flex;
  align-items: center;
  gap: 5px;
}

input[type=text] {
  border: 2px solid #77C8D2;
  border-radius: 15px;
  padding: 5px 10px;
  width: 80%;
  height: 40px;
  text-align: start;
}

input[type=number] {
  appearance: textfield;
  border: 2px solid #77C8D2;
  border-radius: 10px;
  width: 40px;
  height: 40px;
  text-align: center;
}