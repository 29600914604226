.AppLayout-title {
  flex-grow: 1;
}

.AppLayout-langSwitch {
  display: flex;
}

.AppLayout-flag {
  width: 24px;
  padding: 4px 6px;
}
