.Auth {
  background-color: #FFF;
  width: 100%;
  height: 100%;
}

.AuthContent {
  background-color: #FFF;
  width: 80%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
}

.AuthImage {
  background: #031B3D url("https://pfid.pierre-fabre.dev/portal/static/media/bg_2.78adf787c612275a8241.png") no-repeat scroll right top / cover;
  height: 750px;
  width: 36%;
  background-color: #031B3Ded;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.AuthImage img {
  width: 200px;
}

.AuthRightSide {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
}

.AuthLogo {
  display: block;
  width: 180px;
  margin: 0 auto;
}

.AuthRightSideContent {
  width: 60%;
}

.AuthP {
  margin-bottom: 50px;
}

.AuthButton {
  border: solid 1px transparent;
  border-radius: 26px;
  width: 95%;
  line-height: 35px;
  padding: 5px 10px;
  background-color: #1F396F;
  font-size: 16px;
  color: #FFF;
  cursor: pointer;
}

.AuthButtonSecondary {
  margin-top: 20px;
  background-color: #78BDEE;
}


@media only screen and (max-width: 768px) {
  .AuthContent {
    flex-direction: column;
  }

  .AuthImage {
    margin-bottom: 30px;
    padding: 20px 0px;
    background-color: #031B3D;
    height: 100px;
    width: 100%;
  }

  .AuthRightSide, .AuthRightSideContent, .AuthButton {
    width: 100%;
  }
}